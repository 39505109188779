import React from "react"
import { css } from '@emotion/react'

import Layout from "../components/common/Layout"

const text = css({
  textAlign: 'center',
  fontSize: '2rem',
  fontWeight: 'bold'
})

export default () => (
  <Layout>
    <div css={text}>404 PAGE NOT FOUND</div>
  </Layout>
)